import { setContent, toLocalDate, btnTextDay, themeView, btnTextWeek } from '@event-calendar/core';
import TimeGrid, { Section, Body, Week, Day } from '@event-calendar/time-grid';
import { derived } from 'svelte/store';
import { SvelteComponent, init, safe_not_equal, element, attr, insert, action_destroyer, is_function, noop, detach, component_subscribe, binding_callbacks, create_component, space, mount_component, append, transition_in, group_outros, transition_out, check_outros, destroy_component, ensure_array_like, empty, destroy_each, set_store_value } from 'svelte/internal';
import { getContext, onMount } from 'svelte';

function viewResources(state) {
    return derived(
        [state.resources, state.filterResourcesWithEvents, state._events, state._activeRange],
        ([$resources, $filterResourcesWithEvents, $_events, $_activeRange]) => {
            let result = $resources;

            if ($filterResourcesWithEvents) {
                result = $resources.filter(resource => {
                    for (let event of $_events) {
                        if (
                            event.display !== 'background' &&
                            event.resourceIds.includes(resource.id) &&
                            event.start < $_activeRange.end &&
                            event.end > $_activeRange.start
                        ) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            if (!result.length) {
                result = state.resources.parse([{}]);
            }

            return result;
        }
    );
}

/* packages/resource-time-grid/src/Label.svelte generated by Svelte v4.1.1 */

function create_fragment$1(ctx) {
	let div;
	let div_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].day);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			/*div_binding*/ ctx[9](div);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[1]));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].day)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*content*/ 2) setContent_action.update.call(null, /*content*/ ctx[1]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			/*div_binding*/ ctx[9](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $resourceLabelDidMount;
	let $resourceLabelContent;
	let $theme;
	let { resource } = $$props;
	let { date = undefined } = $$props;
	let { resourceLabelContent, resourceLabelDidMount, theme } = getContext('state');
	component_subscribe($$self, resourceLabelContent, value => $$invalidate(8, $resourceLabelContent = value));
	component_subscribe($$self, resourceLabelDidMount, value => $$invalidate(10, $resourceLabelDidMount = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	let el;
	let content;

	onMount(() => {
		if (is_function($resourceLabelDidMount)) {
			$resourceLabelDidMount({
				resource,
				date: date ? toLocalDate(date) : undefined,
				el
			});
		}
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$set = $$props => {
		if ('resource' in $$props) $$invalidate(6, resource = $$props.resource);
		if ('date' in $$props) $$invalidate(7, date = $$props.date);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$resourceLabelContent, resource, date*/ 448) {
			// Content
			if ($resourceLabelContent) {
				$$invalidate(1, content = is_function($resourceLabelContent)
				? $resourceLabelContent({
						resource,
						date: date ? toLocalDate(date) : undefined
					})
				: $resourceLabelContent);
			} else {
				$$invalidate(1, content = resource.titleHTML
				? { html: resource.titleHTML }
				: resource.title);
			}
		}
	};

	return [
		el,
		content,
		$theme,
		resourceLabelContent,
		resourceLabelDidMount,
		theme,
		resource,
		date,
		$resourceLabelContent,
		div_binding
	];
}

class Label extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$1, safe_not_equal, { resource: 6, date: 7 });
	}
}

/* packages/resource-time-grid/src/View.svelte generated by Svelte v4.1.1 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[13] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[16] = list[i];
	return child_ctx;
}

function get_each_context_4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[19] = list[i];
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	return child_ctx;
}

function get_each_context_5(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[13] = list[i];
	return child_ctx;
}

function get_each_context_6(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[16] = list[i];
	return child_ctx;
}

// (19:16) {:else}
function create_else_block_2(ctx) {
	let label;
	let current;
	label = new Label({ props: { resource: /*item0*/ ctx[13] } });

	return {
		c() {
			create_component(label.$$.fragment);
		},
		m(target, anchor) {
			mount_component(label, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const label_changes = {};
			if (dirty[0] & /*loops*/ 8) label_changes.resource = /*item0*/ ctx[13];
			label.$set(label_changes);
		},
		i(local) {
			if (current) return;
			transition_in(label.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(label.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(label, detaching);
		}
	};
}

// (17:16) {#if $datesAboveResources}
function create_if_block_4(ctx) {
	let div;
	let div_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[4].day);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, div, /*$_intlDayHeader*/ ctx[5].format(/*item0*/ ctx[13])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].day)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*$_intlDayHeader, loops*/ 40) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[5].format(/*item0*/ ctx[13]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

// (22:16) {#if loops[1].length > 1}
function create_if_block_2(ctx) {
	let div;
	let div_class_value;
	let current;
	let each_value_6 = ensure_array_like(/*loops*/ ctx[3][1]);
	let each_blocks = [];

	for (let i = 0; i < each_value_6.length; i += 1) {
		each_blocks[i] = create_each_block_6(get_each_context_6(ctx, each_value_6, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[4].days);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*loops, $datesAboveResources, $theme, $_intlDayHeader*/ 60) {
				each_value_6 = ensure_array_like(/*loops*/ ctx[3][1]);
				let i;

				for (i = 0; i < each_value_6.length; i += 1) {
					const child_ctx = get_each_context_6(ctx, each_value_6, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_6(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value_6.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].days)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_6.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (27:28) {:else}
function create_else_block_1(ctx) {
	let div;
	let div_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[4].day);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, div, /*$_intlDayHeader*/ ctx[5].format(/*item1*/ ctx[16])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].day)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*$_intlDayHeader, loops*/ 40) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[5].format(/*item1*/ ctx[16]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

// (25:28) {#if $datesAboveResources}
function create_if_block_3(ctx) {
	let label;
	let current;

	label = new Label({
			props: {
				resource: /*item1*/ ctx[16],
				date: /*item0*/ ctx[13]
			}
		});

	return {
		c() {
			create_component(label.$$.fragment);
		},
		m(target, anchor) {
			mount_component(label, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const label_changes = {};
			if (dirty[0] & /*loops*/ 8) label_changes.resource = /*item1*/ ctx[16];
			if (dirty[0] & /*loops*/ 8) label_changes.date = /*item0*/ ctx[13];
			label.$set(label_changes);
		},
		i(local) {
			if (current) return;
			transition_in(label.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(label.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(label, detaching);
		}
	};
}

// (24:24) {#each loops[1] as item1}
function create_each_block_6(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_3, create_else_block_1];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (/*$datesAboveResources*/ ctx[2]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_1(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(if_block_anchor);
			}

			if_blocks[current_block_type_index].d(detaching);
		}
	};
}

// (15:8) {#each loops[0] as item0}
function create_each_block_5(ctx) {
	let div;
	let current_block_type_index;
	let if_block0;
	let t0;
	let t1;
	let div_class_value;
	let current;
	const if_block_creators = [create_if_block_4, create_else_block_2];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$datesAboveResources*/ ctx[2]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let if_block1 = /*loops*/ ctx[3][1].length > 1 && create_if_block_2(ctx);

	return {
		c() {
			div = element("div");
			if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[4].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			append(div, t0);
			if (if_block1) if_block1.m(div, null);
			append(div, t1);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				} else {
					if_block0.p(ctx, dirty);
				}

				transition_in(if_block0, 1);
				if_block0.m(div, t0);
			}

			if (/*loops*/ ctx[3][1].length > 1) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*loops*/ 8) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			if_blocks[current_block_type_index].d();
			if (if_block1) if_block1.d();
		}
	};
}

// (14:4) <Section>
function create_default_slot_2(ctx) {
	let each_1_anchor;
	let current;
	let each_value_5 = ensure_array_like(/*loops*/ ctx[3][0]);
	let each_blocks = [];

	for (let i = 0; i < each_value_5.length; i += 1) {
		each_blocks[i] = create_each_block_5(get_each_context_5(ctx, each_value_5, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, loops, $datesAboveResources, $_intlDayHeader*/ 60) {
				each_value_5 = ensure_array_like(/*loops*/ ctx[3][0]);
				let i;

				for (i = 0; i < each_value_5.length; i += 1) {
					const child_ctx = get_each_context_5(ctx, each_value_5, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_5(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_5.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_5.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (38:0) {#if $allDaySlot}
function create_if_block(ctx) {
	let div2;
	let div1;
	let section;
	let t;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let div2_class_value;
	let current;

	section = new Section({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			create_component(section.$$.fragment);
			t = space();
			div0 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[4].hiddenScroll);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[4].content);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[4].allDay);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			mount_component(section, div1, null);
			append(div1, t);
			append(div1, div0);
			current = true;
		},
		p(ctx, dirty) {
			const section_changes = {};

			if (dirty[0] & /*$_viewDates, $theme, $_viewResources, $datesAboveResources*/ 23 | dirty[1] & /*$$scope*/ 1) {
				section_changes.$$scope = { dirty, ctx };
			}

			section.$set(section_changes);

			if (!current || dirty[0] & /*$theme*/ 16 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[4].hiddenScroll)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[4].content)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[4].allDay)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(section.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(section.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_component(section);
		}
	};
}

// (50:16) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value_4 = ensure_array_like(/*$_viewResources*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value_4.length; i += 1) {
		each_blocks[i] = create_each_block_4(get_each_context_4(ctx, each_value_4, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, $_viewDates, $_viewResources*/ 19) {
				each_value_4 = ensure_array_like(/*$_viewResources*/ ctx[1]);
				let i;

				for (i = 0; i < each_value_4.length; i += 1) {
					const child_ctx = get_each_context_4(ctx, each_value_4, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_4(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_4.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_4.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (42:16) {#if $datesAboveResources}
function create_if_block_1(ctx) {
	let each_1_anchor;
	let current;
	let each_value_2 = ensure_array_like(/*$_viewDates*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, $_viewResources, $_viewDates*/ 19) {
				each_value_2 = ensure_array_like(/*$_viewDates*/ ctx[0]);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_2.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_2.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (51:20) {#each $_viewResources as resource}
function create_each_block_4(ctx) {
	let div;
	let week;
	let t;
	let div_class_value;
	let current;

	week = new Week({
			props: {
				dates: /*$_viewDates*/ ctx[0],
				resource: /*resource*/ ctx[22]
			}
		});

	return {
		c() {
			div = element("div");
			create_component(week.$$.fragment);
			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[4].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(week, div, null);
			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty[0] & /*$_viewDates*/ 1) week_changes.dates = /*$_viewDates*/ ctx[0];
			if (dirty[0] & /*$_viewResources*/ 2) week_changes.resource = /*resource*/ ctx[22];
			week.$set(week_changes);

			if (!current || dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(week);
		}
	};
}

// (45:28) {#each $_viewResources as resource}
function create_each_block_3(ctx) {
	let week;
	let current;

	week = new Week({
			props: {
				dates: [/*date*/ ctx[19]],
				resource: /*resource*/ ctx[22]
			}
		});

	return {
		c() {
			create_component(week.$$.fragment);
		},
		m(target, anchor) {
			mount_component(week, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty[0] & /*$_viewDates*/ 1) week_changes.dates = [/*date*/ ctx[19]];
			if (dirty[0] & /*$_viewResources*/ 2) week_changes.resource = /*resource*/ ctx[22];
			week.$set(week_changes);
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(week, detaching);
		}
	};
}

// (43:20) {#each $_viewDates as date}
function create_each_block_2(ctx) {
	let div;
	let t;
	let div_class_value;
	let current;
	let each_value_3 = ensure_array_like(/*$_viewResources*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[4].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_viewDates, $_viewResources*/ 3) {
				each_value_3 = ensure_array_like(/*$_viewResources*/ ctx[1]);
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_3(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, t);
					}
				}

				group_outros();

				for (i = each_value_3.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_3.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (41:12) <Section>
function create_default_slot_1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type_2(ctx, dirty) {
		if (/*$datesAboveResources*/ ctx[2]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_2(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_2(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(if_block_anchor);
			}

			if_blocks[current_block_type_index].d(detaching);
		}
	};
}

// (65:8) {#each loops[1] as item1}
function create_each_block_1(ctx) {
	let day;
	let current;

	day = new Day({
			props: {
				date: /*$datesAboveResources*/ ctx[2]
				? /*item0*/ ctx[13]
				: /*item1*/ ctx[16],
				resource: /*$datesAboveResources*/ ctx[2]
				? /*item1*/ ctx[16]
				: /*item0*/ ctx[13]
			}
		});

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};

			if (dirty[0] & /*$datesAboveResources, loops*/ 12) day_changes.date = /*$datesAboveResources*/ ctx[2]
			? /*item0*/ ctx[13]
			: /*item1*/ ctx[16];

			if (dirty[0] & /*$datesAboveResources, loops*/ 12) day_changes.resource = /*$datesAboveResources*/ ctx[2]
			? /*item1*/ ctx[16]
			: /*item0*/ ctx[13];

			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

// (63:0) {#each loops[0] as item0}
function create_each_block(ctx) {
	let div;
	let t;
	let div_class_value;
	let current;
	let each_value_1 = ensure_array_like(/*loops*/ ctx[3][1]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[4].resource);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$datesAboveResources, loops*/ 12) {
				each_value_1 = ensure_array_like(/*loops*/ ctx[3][1]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, t);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div_class_value !== (div_class_value = /*$theme*/ ctx[4].resource)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (62:0) <Body>
function create_default_slot(ctx) {
	let each_1_anchor;
	let current;
	let each_value = ensure_array_like(/*loops*/ ctx[3][0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, loops, $datesAboveResources*/ 28) {
				each_value = ensure_array_like(/*loops*/ ctx[3][0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let section;
	let t0;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let t1;
	let t2;
	let body;
	let current;

	section = new Section({
			props: {
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	let if_block = /*$allDaySlot*/ ctx[6] && create_if_block(ctx);

	body = new Body({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div1 = element("div");
			create_component(section.$$.fragment);
			t0 = space();
			div0 = element("div");
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			create_component(body.$$.fragment);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[4].hiddenScroll);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[4].header);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(section, div1, null);
			append(div1, t0);
			append(div1, div0);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, t2, anchor);
			mount_component(body, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const section_changes = {};

			if (dirty[0] & /*loops, $theme, $datesAboveResources, $_intlDayHeader*/ 60 | dirty[1] & /*$$scope*/ 1) {
				section_changes.$$scope = { dirty, ctx };
			}

			section.$set(section_changes);

			if (!current || dirty[0] & /*$theme*/ 16 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[4].hiddenScroll)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty[0] & /*$theme*/ 16 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[4].header)) {
				attr(div1, "class", div1_class_value);
			}

			if (/*$allDaySlot*/ ctx[6]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*$allDaySlot*/ 64) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t2.parentNode, t2);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			const body_changes = {};

			if (dirty[0] & /*loops, $theme, $datesAboveResources*/ 28 | dirty[1] & /*$$scope*/ 1) {
				body_changes.$$scope = { dirty, ctx };
			}

			body.$set(body_changes);
		},
		i(local) {
			if (current) return;
			transition_in(section.$$.fragment, local);
			transition_in(if_block);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(section.$$.fragment, local);
			transition_out(if_block);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
				detach(t1);
				detach(t2);
			}

			destroy_component(section);
			if (if_block) if_block.d(detaching);
			destroy_component(body, detaching);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $_viewDates;
	let $_viewResources;
	let $datesAboveResources;
	let $theme;
	let $_intlDayHeader;
	let $allDaySlot;
	let { datesAboveResources, _viewDates, _viewResources, _intlDayHeader, allDaySlot, theme } = getContext('state');
	component_subscribe($$self, datesAboveResources, value => $$invalidate(2, $datesAboveResources = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(0, $_viewDates = value));
	component_subscribe($$self, _viewResources, value => $$invalidate(1, $_viewResources = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(5, $_intlDayHeader = value));
	component_subscribe($$self, allDaySlot, value => $$invalidate(6, $allDaySlot = value));
	component_subscribe($$self, theme, value => $$invalidate(4, $theme = value));
	let loops;

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$datesAboveResources, $_viewDates, $_viewResources*/ 7) {
			$$invalidate(3, loops = $datesAboveResources
			? [$_viewDates, $_viewResources]
			: [$_viewResources, $_viewDates]);
		}
	};

	return [
		$_viewDates,
		$_viewResources,
		$datesAboveResources,
		loops,
		$theme,
		$_intlDayHeader,
		$allDaySlot,
		datesAboveResources,
		_viewDates,
		_viewResources,
		_intlDayHeader,
		allDaySlot,
		theme
	];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment, safe_not_equal, {}, null, [-1, -1]);
	}
}

/* packages/resource-time-grid/src/Auxiliary.svelte generated by Svelte v4.1.1 */

function instance($$self, $$props, $$invalidate) {
	let $resources;
	let $_resTxtColor;
	let $_resBgColor;
	let { resources, _resBgColor, _resTxtColor } = getContext('state');
	component_subscribe($$self, resources, value => $$invalidate(3, $resources = value));
	component_subscribe($$self, _resBgColor, value => $$invalidate(5, $_resBgColor = value));
	component_subscribe($$self, _resTxtColor, value => $$invalidate(4, $_resTxtColor = value));

	set_store_value(
		_resBgColor,
		$_resBgColor = event => {
			let resource = $resources.find(res => event.resourceIds.includes(res.id));
			return resource?.eventBackgroundColor;
		},
		$_resBgColor
	);

	set_store_value(
		_resTxtColor,
		$_resTxtColor = event => {
			let resource = $resources.find(res => event.resourceIds.includes(res.id));
			return resource?.eventTextColor;
		},
		$_resTxtColor
	);

	return [resources, _resBgColor, _resTxtColor];
}

class Auxiliary extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, null, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		options.resources = [];
		options.datesAboveResources = false;
		options.filterResourcesWithEvents = false;
		options.resourceLabelContent = undefined;
		options.resourceLabelDidMount = undefined;
		// Common options
		options.buttonText.resourceTimeGridDay = 'day';
		options.buttonText.resourceTimeGridWeek = 'week';
		options.theme.resource = 'ec-resource';
		options.theme.resourceTitle = 'ec-resource-title';
		options.view = 'resourceTimeGridWeek';
		options.views.resourceTimeGridDay = {
			buttonText: btnTextDay,
			component: View,
			duration: {days: 1},
			theme: themeView('ec-time-grid ec-resource-day-view')
		};
		options.views.resourceTimeGridWeek = {
			buttonText: btnTextWeek,
			component: View,
			duration: {weeks: 1},
			theme: themeView('ec-time-grid ec-resource-week-view')
		};
	},

	createParsers(parsers) {
		parsers.resources = createResources;
	},

	createStores(state) {
		if (!('_times' in state)) {
			TimeGrid.createStores(state);
		}
		state._auxiliary.update($_auxiliary => [...$_auxiliary, Auxiliary]);
		state._viewResources = viewResources(state);
	}
};

function createResources(input) {
	return input.map(resource => ({
		id: String(resource.id),
		title: resource.title || '',
		titleHTML: resource.titleHTML || '',
		eventBackgroundColor: resource.eventBackgroundColor,
        eventTextColor: resource.eventTextColor
	}));
}

export { index as default };
